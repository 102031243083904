import React from 'react'

function Gallery() {
    return (
        <div className='pb-[80px]'>
            <div>
                <p className="cartoon-font text-center text-[70px] sm:text-[90px]  md:text-[150px] text-[#B22525] border-black">Gallery</p>
            </div>
            <div class="flex flex-wrap px-6">
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
                        return <div class="w-1/4 px-2 mb-4 flex items-end">
                            <img src={require(`../Assets/${item}.png`)} />
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default Gallery