import logo from './logo.svg';
import './App.css';
import Hero from './components/hero';
import Slider from './components/Slider';
import Gallery from './components/Gallery';
import About from './components/About';
import Tokenomics from './components/Tokenomics';

function App() {
  return (
    <div className="App bg-main">
      <Hero />
      <Slider />
      <Gallery/>
      <Slider />
      <About/>
      <Slider />
      <Tokenomics/>
      <Slider />
    </div>
  );
}

export default App;
