import React from 'react'
import Marquee from 'react-fast-marquee'
// import { primaryColor, secondaryColor } from './constants'

function Slider() {
    return (
        <div className='relative'>
            <section style={{ background: 'red' }} className='w-[100vw] border-y-4 border-[#FFA927]   py-4 max-w-[100%] flex items-center'>
                <Marquee direction='right' >
                    <div className='flex gap-[60px] items-center '>
                        <img className='h-[90px]' src={require('../Assets/1.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Chu dong</p>
                        <img className='h-[90px]' src={require('../Assets/2.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Les goh </p>
                        <img className='h-[90px]' src={require('../Assets/3.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Fat kok</p>
                        <img className='h-[90px]' src={require('../Assets/4.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Bai fat</p>
                        <img className='h-[90px]' src={require('../Assets/5.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Fuk yu </p>
                        <img className='h-[90px]' src={require('../Assets/6.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Chu Mei Goo</p>
                        <img className='h-[90px]' src={require('../Assets/7.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Chin Tu Phat </p>
                        <img className='h-[90px]' src={require('../Assets/8.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Yu So Dum</p>
                        <img className='h-[90px]' src={require('../Assets/9.png')} />
                        <p className='text-[64px] cartoon-font text-[#FFA927]'>Dam sun</p>
                    </div>
                </Marquee>
            </section>
            {/* <img className='block min-w-full absolute h-[70px] bottom-[-20px] z-40' src={require('../Assets/1.png')}/> */}

        </div>
    )
}

export default Slider