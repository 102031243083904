import React from 'react'

function Tokenomics() {
    return (
        <div className='pb-[100px]'>
            <p className="cartoon-font text-center text-[50px] sm:text-[90px]  md:text-[120px] text-[#B22525] border-black">Tokenomics</p>
            <div className='flex max-w-[100dvw] justify-center'>
                <img src={require('../Assets/1.png')} className=' max-h-[]' />
            </div>
        </div>
    )
}

export default Tokenomics