import React from 'react'

function Hero() {
    return (
        <div>
            <img src={require('../Assets/nav.png')} className='w-[100dvw] max-h-[260px]' />
            <div className='flex max-w-[100dvw] justify-center'>
                <img src={require('../Assets/1.png')} className='transate-y-[0px] md:translate-y-[-30px] max-h-[250px]' />
            </div>
            <p className='cartoon-font text-center text-[100px] sm:text-[140px] translate-y-[-30px] md:translate-y-[-100px] md:text-[240px] text-[#B22525] border-black'>
                CHAN
            </p>
            <div className='flex justify-center gap-4 translate-y-[-30px] md:translate-y-[-80px] '>
                <a href='https://x.com/chanxtron'>
                    <div className='w-fit rounded-lg border-4 border-[#401C20] bg-[#B22525] p-4'>
                        <p className='cartoon-font text-white'>Twitter</p>
                    </div>
                </a>
                <a href="https://t.me/chinesehilariousassnames">
                    <div className='w-fit rounded-lg border-4 border-[#401C20] bg-[#B22525] p-4'>
                        <p className='cartoon-font text-white'>Telegram</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Hero