import React from 'react'

function About() {
    return (
        <div className='pb-[100px]'>
            <div>
                <p className="cartoon-font text-center text-[70px] sm:text-[90px]  md:text-[150px] text-[#B22525] border-black">
                    About
                </p>
                <div className='flex max-w-[100dvw] justify-center'>
                    <img src={require('../Assets/5.png')} className=' max-h-[]' />
                </div>
                <p className='text-[24px] leading-[24px] md:leading-[60px] md:text-[64px] px-8 md:px-16 cartoon-font2 '>
                Embrace the chaos of Chinese memes with this token! No serious goals or grand promises—just pure, unfiltered hilarity. Every transaction celebrates internet absurdity. If you're in it for the laughs and a touch of meme magic, you're in the right place. In the meme universe, it's all about enjoying the ridiculous ride.
                </p>
            </div>
        </div>
    )
}

export default About